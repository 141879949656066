/* ========================================================================
 * Apricot's Popover
 * ========================================================================
 *
 * This plugin is depended on
 * https://github.com/FezVrasta/popper.js
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import "../scss/includes/popover.scss";

// javaScript
import {
  createPopper
} from '@popperjs/core';
import Utils from './CBUtils'


/**
 * Popover
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Element} data.popoverNode
 * @param {String} data.placement
 * @param {Number|Object} data.delay
 * @param {Boolean} data.html
 * @param {String} data.template
 * @param {String} data.headerTag 
 * @param {String|HTMLElement|TitleFunction} data.title
 * @param {String|HTMLElement|TitleFunction} data.content
 * @param {String} data.innerSelector
 * @param {String} data.headerSelector 
 * @param {String} data.contentSelector
 * @param {String} data.trigger
 * @param {String} data.closeTrigger
 * @param {Boolean} data.closeButton
 * @param {Boolean} data.closeOnClickOutside
 * @param {Array} data.offset
 * @param {Array} data.flipVariations
 * @param {Boolean} data.removeOnDestroy
 * @param {HTMLElement|String|false} data.container
 * @param {Array} modifiers 
 * @param {String} data.style
 * @param {Boolean} data.filter
 * @returns {{show: Function}} 
 * @returns {{hide: Function}} 
 * @returns {{deactivate: Function}} 
 * @returns {{toggle: Function}} 
 * @returns {{updateTitle: Function}} 
 * @returns {{updateContent: Function}} 
 * @returns {{destroy: Function}} 
 */

const Popover = (data = {}) => {
  const defaultData = {
    elem: null,
    popoverNode: null,
    placement: 'top',
    delay: {
      show: 200,
      hide: 100
    },
    html: false,
    template: '<div class="cb-popover" role="region"><div class="cb-popover-inner"><div class="cb-popover-header"></div><div class="cb-popover-content"></div></div></div>',

    headerTag: 'h3',
    title: '',
    content: '',
    innerSelector: '.cb-popover-inner',
    headerSelector: '.cb-popover-header',
    contentSelector: '.cb-popover-content',
    trigger: 'click',
    closeButton: false,
    closeOnClickOutside: true,
    offset: [0, 12],
    flipVariations: ['top', 'bottom'],
    removeOnDestroy: true,
    container: false,
    modifiers: [],
    style: '',
    filter: false
  }

  data = {
    ...defaultData,
    ...data
  };

  let elem = data.elem;
  let popoverNode = null;

  let events = [];

  if (!Utils.elemExists(elem)) return null;

  // set initial state
  let isOpen = false;
  let isOpening = false;
  let isActive = true;

  let delay = data.delay;
  let title = null;
  let content = null;
  let closeButton = false;

  let showTimeout = null
  let popperInstance = null
  let elemId = ''

  const init = () => {
    elem.popover = 'cb';

    // get title
    title = (elem.getAttribute('data-cb-title') || elem.getAttribute('title')) || data.title;
    content = (elem.getAttribute('data-cb-content') || elem.getAttribute('content')) || data.content;
    closeButton = elem.getAttribute('data-cb-close-button') || data.closeButton;

    elemId = Utils.attr(elem, 'id') || Utils.uniqueID(5, 'apricot_');
    elem.setAttribute('id', elemId);

    popoverNode = data.popoverNode || document.querySelector(`#${elem.getAttribute('aria-controls')}`)
    a11y()

    // get events list
    events =
      typeof data.trigger === 'string' ?
      data.trigger
      .split(' ')
      .filter(
        trigger => ['click', 'focus', 'hover'].indexOf(trigger) !== -1
      ) : [];

    events = [...(new Set(events))]

    setEventListeners(events)
  }

  const a11y = () => {
    if (!popoverNode) return
    let titleId = null
    const popoverId = popoverNode.getAttribute('id') || Utils.uniqueID(5, 'apricot_');
    popoverNode.setAttribute('id', popoverId);
    popoverNode.setAttribute('aria-hidden', 'true');
    popoverNode.setAttribute('tabIndex', '-1');

    elem.setAttribute('aria-controls', popoverId);

    const title = popoverNode.querySelector('.cb-popover-title')
    if (Utils.elemExists(title)) {
      titleId = title.getAttribute('id') || Utils.uniqueID(5, 'apricot_');
      title.setAttribute('id', titleId);
      popoverNode.setAttribute('aria-labelledby', titleId);
    } else {
      popoverNode.setAttribute('aria-labelledby', elemId);
    }

    const closeBtn = popoverNode.querySelector('.cb-popover-close .cb-btn-close')
    if (Utils.elemExists(closeBtn)) {
      Utils.attr(closeBtn, 'aria-describedby', titleId)
    }

    if (data.style) {
      Utils.addClass(popoverNode, data.style)
    }
  }

  const setEventListeners = (events) => {
    const directEvents = [];
    const oppositeEvents = [];

    events.forEach(event => {
      switch (event) {
        case 'hover':
          directEvents.push('mouseenter');
          oppositeEvents.push('mouseleave');
          break;
        case 'focus':
          directEvents.push('focus');
          oppositeEvents.push('blur');
          break;
        case 'click':
          directEvents.push('click');
          oppositeEvents.push('click');
          break;
      }
    });

    // schedule show popover
    directEvents.forEach(event => {
      const func = evt => {
        if (isOpening === true) {
          return;
        }
        evt.usedByPopover = true;
        scheduleShow(evt);
      };
      events.push({
        event,
        func
      });
      elem.addEventListener(event, func);
    });

    // schedule hide popover
    oppositeEvents.forEach(event => {
      const func = evt => {
        if (evt.usedByPopover === true) {
          return;
        }

        scheduleHide(evt);
      };
      events.push({
        event,
        func
      });
      elem.addEventListener(event, func);

      if (event === 'click' && data.closeOnClickOutside) {
        document.addEventListener('mousedown', e => {
          if (!isOpening) {
            return;
          }
          if (elem.contains(e.target) ||
            popoverNode.contains(e.target)) {
            return;
          }
          func(e);
        }, true);
      }

      // A11Y
      if (data.closeOnClickOutside) {
        const body = document.getElementsByTagName('body')[0]
        // Close on ESC
        document.addEventListener('keyup', e => {
          if (!isOpening) {
            return;
          } else if (Utils.whichKey(e) === 'ESC' && !Utils.attr(body, 'data-cb-toast')) {

            func(e);
          } else {
            if (elem.contains(e.target) || popoverNode.contains(e.target)) {

              return;
            }
            func(e);
          }
        }, true);
      }
    });
  }

  const setPopoverNodeEvent = (evt) => {
    const relatedReference =
      evt.relatedReference || evt.toElement || evt.relatedTarget;

    const callback = evt2 => {
      const relatedReference2 =
        evt2.relatedReference || evt2.toElement || evt2.relatedTarget;

      // Remove event listener after call
      popoverNode.removeEventListener(evt.type, callback);

      // If the new reference is not the reference element
      if (!elem.contains(relatedReference2)) {
        // Schedule to hide popover
        scheduleHide(evt2);
      }
    };

    if (popoverNode.contains(relatedReference)) {
      // listen to mouseleave on the popover element to be able to hide the popover
      popoverNode.addEventListener(evt.type, callback);
      return true;
    }

    return false;
  };

  const scheduleShow = (evt) => {
    isOpening = true;
    // defaults to 0
    const computedDelay = (delay && delay.show) || delay || 0;
    showTimeout = window.setTimeout(
      () => {
        show()
      },
      computedDelay
    );
  }

  const scheduleHide = (evt) => {
    isOpening = false;
    // defaults to 0
    const computedDelay = (delay && delay.hide) || delay || 0;
    window.clearTimeout(showTimeout);
    window.setTimeout(() => {
      if (isOpen === false) {
        return;
      }
      if (!document.body.contains(popoverNode)) {
        return;
      }

      // if we are hiding because of a mouseleave, we must check that the new
      // reference isn't the popover, because in this case we don't want to hide it
      if (evt.type === 'mouseleave') {
        const isSet = setPopoverNodeEvent(evt);

        // if we set the new event, don't hide the popover yet
        // the new event will take care to hide it if necessary
        if (isSet) {
          return;
        }
      }

      hide();
    }, computedDelay);
  }

  const createPopover = () => {
    let popNode = null
    let popoverGenerator = null
    let titleId = null

    // create popover element
    popoverGenerator = window.document.createElement('div');
    popoverGenerator.innerHTML = data.template.trim();
    popNode = popoverGenerator.childNodes[0];

    // add unique ID to our popover (needed for accessibility reasons)
    popNode.id = Utils.uniqueID(5, 'apricot_');

    // add title to popover
    const headerContainer = popoverGenerator.querySelector(data.headerSelector);
    if (!Utils.isBlank(title)) {
      const headerNode = window.document.createElement(data.headerTag);
      titleId = Utils.uniqueID(5, 'apricot_');
      Utils.addClass(headerNode, 'cb-popover-title')
      Utils.attr(headerNode, 'id', titleId)
      Utils.append(headerContainer, headerNode)
      addContent(title, headerNode, data.html);

      popNode.setAttribute('aria-labelledby', titleId);
    } else {

      popNode.setAttribute('aria-labelledby', elemId);
    }
    // popover with close button
    if (closeButton) {
      const button = document.createElement('BUTTON')
      Utils.attr(button, 'type', 'button')
      Utils.addClass(button, ['cb-btn', 'cb-btn-square', 'cb-btn-greyscale', 'cb-btn-close'])
      Utils.attr(button, 'aria-describedby', titleId)
      Utils.attr(button, 'data-cb-popover-close', 'true')

      const glyph = document.createElement('SPAN')
      Utils.addClass(glyph, 'cb-glyph');
      Utils.addClass(glyph, 'cb-x-mark');
      Utils.attr(glyph, 'aria-hidden', 'true');
      Utils.append(button, glyph);

      const span = document.createElement('SPAN')
      Utils.addClass(span, 'sr-only');
      span.innerHTML = "Close Popover"
      Utils.append(button, span);

      Utils.addClass(headerContainer, 'cb-popover-close');
      Utils.append(headerContainer, button)
    }

    if (!closeButton && Utils.isBlank(title)) {
      Utils.remove(headerContainer)
    }

    const contentNode = popoverGenerator.querySelector(data.contentSelector);
    if (!Utils.isBlank(content)) {
      // add content to popover
      addContent(content, contentNode, data.html);
    } else {
      Utils.remove(contentNode)

      if (Utils.elemExists(headerContainer)) {
        Utils.addClass(headerContainer, 'cb-no-margin')
      }
    }

    // Adjust style
    if (data.style) {
      Utils.addClass(popNode, data.style)
    }

    // return the generated popover node
    return popNode;
  }

  const addContent = (title, titleNode, allowHtml) => {
    if (title.nodeType === 1 || title.nodeType === 11) {
      // if title is a element node or document fragment, append it only if allowHtml is true
      allowHtml && titleNode.appendChild(title);
    } else if (Utils.isFunction(title)) {
      // Recursively call ourself so that the return value of the function gets handled appropriately - either
      // as a dom node, a string, or even as another function.
      addContent(title.call(elem), titleNode, allowHtml);
    } else {
      // if it's just a simple text, set textContent or innerHtml depending by `allowHtml` value
      allowHtml ? (titleNode.innerHTML = title) : (titleNode.textContent = title);
    }
  }

  // popover will be added to this
  const findContainer = () => {
    let container = null
    // if container is a query, get the relative element
    if (typeof data.container === 'string') {
      container = window.document.querySelector(data.container);
    } else if (data.container === false) {
      // if container is `false`, set it to elem parent

      container = elem.parentNode;
    }

    return container;
  }

  const clearContent = (lastTitle, titleNode, allowHtml) => {
    if (lastTitle.nodeType === 1 || lastTitle.nodeType === 11) {
      allowHtml && titleNode.removeChild(lastTitle);
    } else {
      allowHtml ? titleNode.innerHTML = '' : titleNode.textContent = '';
    }
  }

  const setFocusToContainer = () => {
    if (popoverNode) {
      setTimeout(() => {

        popoverNode.focus()
      }, 30);
    }
  }

  const getClosingNodes = () => {
    const nodes = popoverNode.querySelectorAll('[data-cb-popover-close]')

    nodes.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault()

        if (!isOpening) {
          return;
        } else {
          scheduleHide(e);
        }
      });
    })
  }

  /**
   * Reveals a popover. This is considered a "manual" triggering of the popover.
   * Popover with zero-length titles are never displayed.
   * @method Popover#show
   * @memberof Popover
   */
  const show = () => {
    // check if we should proceed
    if (!isActive) {
      return;
    }
    // don't show if it's already visible
    // or if it's not being showed
    if (isOpen && !isOpening) {
      return;
    }

    isOpen = true;

    // if the popoverNode already exists, just show it
    if (popoverNode && popperInstance) {
      elem.setAttribute('aria-expanded', true);
      elem.setAttribute('aria-describedby', popoverNode.id);

      popoverNode.setAttribute('aria-hidden', 'false');
      popoverNode.style.visibility = 'visible';
      popperInstance.forceUpdate()

      setFocusToContainer();
      getClosingNodes();

      // Trigger event
      const event = new CustomEvent('apricot_popover_show')
      elem.dispatchEvent(event);

      return;
    } else if (!popoverNode) {
      popoverNode = createPopover();

      elem.setAttribute('aria-controls', popoverNode.id);
      popoverNode.setAttribute('tabIndex', '-1');
      popoverNode.setAttribute('aria-hidden', 'true');

      // append popover to container
      const container = findContainer();
      container.appendChild(popoverNode);
    }

    elem.setAttribute('aria-expanded', true);
    elem.setAttribute('aria-describedby', popoverNode.id);
    popoverNode.setAttribute('aria-hidden', 'false');
    popoverNode.style.visibility = 'visible';

    let placementOpt = elem.getAttribute('data-cb-placement') || data.placement

    // offset
    let offsetObj = {
      name: 'offset',
      options: {
        offset: data.offset,
      },
    }

    const flipObj = {
      name: 'flip',
      options: {
        fallbackPlacements: data.flipVariations,
      }
    }

    const modifiersArr = [
      flipObj,
      offsetObj
    ]

    const popperOptions = {
      placement: placementOpt,
      modifiers: [
        ...modifiersArr,
        ...data.modifiers,
      ]
    };

    popperInstance = createPopper(elem, popoverNode, popperOptions);

    setFocusToContainer();
    getClosingNodes();

    // Trigger event
    const event = new CustomEvent('apricot_popover_show')
    elem.dispatchEvent(event);
  }

  /**
   * Hides an element’s popover. This is considered a “manual” triggering of the popover.
   * @method Popover#hide
   * @memberof Popover
   */
  const hide = () => {
    // don't hide if it's already hidden
    if (!isOpen) {
      return;
    }

    let focused = document.activeElement
    isOpen = false;

    // hide popoverNode
    popoverNode.style.visibility = 'hidden';
    popoverNode.setAttribute('aria-hidden', 'true');

    // A11Y
    if (elem) {
      elem.setAttribute('aria-expanded', false)
      elem.removeAttribute('aria-describedby');

      // Make sure focus only goes back to trigger if activeElement 
      // is in the popover scope
      if (!focused || focused == document.body) {
        focused = null;
      } else if (document.querySelector) {
        focused = document.querySelector(":focus")
      }

      if (elem.contains(focused) || popoverNode.contains(focused)) {
        elem.focus()
      }
    }

    // Trigger event
    const event = new CustomEvent('apricot_popover_hide')
    elem.dispatchEvent(event);
  }

  const dispose = () => {
    // remove event listeners first to prevent any unexpected behaviour
    isActive = false
    events.forEach(({
      func,
      event
    }) => {
      if (event) {
        elem.removeEventListener(event, func);
      }
    });

    events = [];

    hide();
    if (popoverNode) {
      // destroy popoverNode 
      popoverNode.parentNode && popoverNode.parentNode.removeChild(popoverNode)
      // destroys
      popperInstance && popperInstance.destroy();

      elem.removeAttribute('aria-describedby');
      elem.removeAttribute('aria-controls');

      popoverNode = null;
    }
  }

  /**
   * Updates the popover's title content
   * @method Popover#updateTitle
   * @memberof Popover
   * @param {String|HTMLElement} title - The new content to use for the title
   */
  const updateTitle = (title) => {
    if (typeof popoverNode === 'undefined') {
      if (typeof data.title !== 'undefined') {
        data.title = title;
      }
      return;
    }

    const titleNode = popoverNode.querySelector(data.innerSelector);
    clearContent(title, titleNode, data.html)
    addContent(title, titleNode, data.html);
    data.title = title;
  }

  /**
   * Updates the popover's title content
   * @method Popover#updateContent
   * @memberof Popover
   * @param {String|HTMLElement} content - The new content to use for the content section
   */
  const updateContent = (content) => {
    if (typeof popoverNode === 'undefined') {
      if (typeof data.content !== 'undefined') {
        data.content = content;
      }
      return;
    }

    const contentNode = popoverNode.querySelector(data.innerSelector);
    clearContent(content, contentNode, data.html)
    addContent(content, contentNode, data.html);
    data.content = content;
  }

  /**
   * Deactivate the popover
   * @method Popover#deactivate
   * @memberof Popover
   * @param {Boolean} mode - If true deactivate, else activate back
   */
  const deactivate = (mode) => {
    isActive = !mode;
  }

  /**
   * Toggles an element’s popover. This is considered a “manual” triggering of the popover.
   * @method Popover#toggle
   * @memberof Popover
   */
  const toggle = () => {
    if (!isActive) {
      return;
    }

    if (isOpen) {
      return hide();
    } else {
      return show();
    }
  }

  /**
   * Destroy popover plugin.
   * @method Popover#destroy
   * @memberof Popover
   */
  const destroy = () => {
    if (elem.popover === 'cb') {
      elem.popover = null

      dispose();
    }
  }

  if (elem.popover !== 'cb') {
    init();
  }

  return {
    show: show,
    hide: hide,
    deactivate: deactivate,
    toggle: toggle,
    updateTitle: updateTitle,
    updateContent: updateContent,
    destroy: destroy
  }
}

export default Popover