import * as components from './components';
import packageJson from '../package.json';

export * from './components';
export {default as CBInclude} from './js/CBInclude';

window.cb = window.cb || {};
window.cb.apricot = {
  version: packageJson.version,
  ...components
};
