/* ========================================================================
 * Apricot's Anchor Menu
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/button.scss';
import '../scss/includes/menu-list.scss';
import '../scss/includes/menu-bar.scss';
import '../scss/includes/anchorMenu.scss';

// javaScript
import Utils from './CBUtils'
import Animation from './CBAnimation'

/**
 * Anchor Menu
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Element} data.container
 * @param {String} data.selector
 * @param {Number} data.offsetTop
 * @param {Boolean} data.close
 * @param {Boolean} data.markup
 * @param {Boolean} data.bookmark
 * @param {Boolean} data.animation
 * @param {Number} data.animationTime
 * @returns {{destroy: Function}} 
 */

const AnchorMenu = (data = {}) => {
  const defaultData = {
    elem: null,
    container: null,
    selector: 'h2',
    offsetTop: 120,
    close: true,
    markup: true,
    bookmark: true,
    animation: true,
    animationTime: 500
  }

  data = {
    ...defaultData,
    ...data
  };

  let elem = data.elem
  let container = data.container

  let btn = null
  let nav = null

  let offsetTop = parseInt(data.offsetTop, 10)
  let selector = data.selector

  if (!Utils.elemExists(elem)) return null;
  if (!Utils.elemExists(container)) return null;

  const init = () => {
    elem.anchorMenu = 'cb';

    btn = elem.querySelector('.cb-anchor-menu-btn')
    nav = elem.querySelector('.cb-menu-list')
    if (data.markup) {
      createLinkList()
    }

    btn.addEventListener('click', openClose)
    btn.addEventListener('keypress', keyboardInteraction)

    document.addEventListener('mousedown', docMouseInteraction, true)
    document.addEventListener('keydown', docKeyboardInteraction, true)

    addBookmarkEvent()
  }

  const createLinkList = () => {
    nav.innerHTML = ''
    const links = getBookmarks()
    const ul = document.createElement('UL')

    links.forEach(link => {
      const li = document.createElement('LI')
      const a = document.createElement('A')
      Utils.attr(a, 'href', `#${link.id}`)
      Utils.attr(a, 'tabindex', '-1')
      Utils.addClass(a, 'cb-menu-link')
      a.innerHTML = link.label

      li.appendChild(a)
      ul.appendChild(li)
    })

    nav.appendChild(ul)
  }

  const getBookmarks = () => {
    let data = []

    container.querySelectorAll(selector).forEach(function (bookmark, index) {
      const obj = {}
      obj.id = `bookmark${index + 1}`
      obj.label = bookmark.innerText

      Utils.attr(bookmark, 'id', `bookmark${index + 1}`)
      data.push(obj)
    })

    return data
  }

  const addBookmarkEvent = () => {
    elem.querySelectorAll('.cb-menu-list a').forEach(function (link) {
      link.addEventListener('click', activateBookmark)

      link.addEventListener('keypress', (e) => {
        if (Utils.whichKey(e) === 'ENTER' || Utils.whichKey(e) === 'SPACE') {
          e.preventDefault();

          activateBookmark(e)
        }
      })
    })
  }

  const activateBookmark = (e) => {
    e.preventDefault();

    if (e.target.hash) {
      let targetEl = document.querySelector(e.target.hash);

      if (data.bookmark) {
        let targetElPos = Utils.offset(targetEl) ? Utils.offset(targetEl).top : 0;
       
        if (data.animation) {
          let top = targetElPos - offsetTop
          let ms = data.animationTime
          // this is not be supported in all browsers
          if (Utils.reduceMotionChanged()) ms = 0

          Animation.pageScrollAnimate(
            top,
            ms,
            'linear',
            setFocus(targetEl)
          )
        } else {
          window.scrollTo({
            top: targetElPos - offsetTop,
            left: 0,
            behavior: 'smooth'
          })

          setFocus(targetEl)
        }
      } else {

        setFocus(targetEl)
      }
    }

    if (data.close) {
      openClose()
    }
  }


  const setFocus = (targetEl) => {
    if (Utils.elemExists(targetEl)) {
      // A11Y
      Utils.attr(targetEl, 'tabindex', '-1')
      if (targetEl.tagName !== 'A') {
        Utils.addClass(targetEl, 'cb-no-outline')
      }
      targetEl.focus()
    }
  }



  const keyboardInteraction = (e) => {
    if (Utils.whichKey(e) === 'ENTER' || Utils.whichKey(e) === 'SPACE') {
      e.preventDefault();

      openClose(e)
    }
  }

  const docMouseInteraction = (e) => {
    if (!Utils.hasClass(elem, 'cb-open')) return;
    if (elem.contains(e.target)) return;

    openClose()
  }

  const docKeyboardInteraction = (e) => {
    // If toast esc is not in place
    const body = document.getElementsByTagName('body')[0]
    if (!Utils.hasClass(elem, 'cb-open') || Utils.attr(body, 'data-cb-toast')) return

    if (e.keyCode === 27) {

      openClose(e)
    }
  }

  const openClose = (e) => {
    if (e) e.preventDefault()

    Utils.toggleClass(elem, 'cb-open')
    if (Utils.hasClass(elem, 'cb-open')) {
      showHideLink(true)
      Utils.attr(btn, 'aria-expanded', 'true')
      if (elem.querySelectorAll('a.cb-menu-link').length > 0) {

        elem.querySelectorAll('a.cb-menu-link')[0].focus()
      }
    } else {
      showHideLink(false)
      Utils.attr(btn, 'aria-expanded', 'false')

      if (elem.contains(document.activeElement) || nav.contains(document.activeElement)) {
        setTimeout(() => {
          btn.focus()
        }, 50)
      }
    }
  }

  const showHideLink = (mode) => {
    elem.querySelectorAll('.cb-menu-list a').forEach(function (link) {
      Utils.attr(link, 'tabindex', (mode) ? '0' : '-1')
    })
  }

  const destroy = () => {
    if (elem.anchorMenu === 'cb') {
      elem.anchorMenu = null

      Utils.removeClass(elem, 'cb-open')

      if (data.markup) {

        nav.innerHTML = ''
      } else {
        showHideLink(false)
      }

      btn.removeEventListener('click', openClose)
      btn.removeEventListener('keypress', keyboardInteraction)

      document.removeEventListener('mousedown', docMouseInteraction, true)
      document.removeEventListener('keypress', docKeyboardInteraction, true)
    }
  }

  if (elem.anchorMenu !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

export default AnchorMenu