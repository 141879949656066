/* ========================================================================
 * Apricot's Glyphs
* ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/glyph.scss';


// javaScript
export default {}