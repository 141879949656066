/* ========================================================================
 * Apricot's Tabs
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/tab.scss';
import '../scss/includes/glyph.scss';
import '../scss/includes/menu-list.scss';

// javaScript
import Utils from './CBUtils'

/**
 * Tabs
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Function} data.callBack
 * @returns {{destroy: Function}} 
 */
const Tabs = (data = {}) => {
  const defaultData = {
    elem: null,
    callBack: null
  }

  data = { ...defaultData, ...data };

  let elem = data.elem;
  let callBack = data.callBack
  const tabs = elem.getElementsByTagName('a')
  let panels = []

  if (!Utils.elemExists(elem)) return null;

  const init = () => {
    elem.tabs = 'cb';

    Array.prototype.forEach.call(tabs, (tab, index) => {
      const id = Utils.attr(tab, 'href').replace('#', '')
      const panel = document.getElementById(id)

      if (Utils.elemExists(panel)) {
        panels.push(panel)
      }

      a11y(tab)
      if (Utils.attr(tab, 'aria-disabled') == 'true') {
        Utils.attr(tab, 'tabindex', '-1')
        tab.addEventListener('click', function (e) {
          e.preventDefault();
        })
      } else {
        addEvents(tab)
      }
    })
  }

  const a11y = (tab) => {
    const id = Utils.attr(tab, 'href').replace('#', '')
    const panel = document.getElementById(id)

    if (Utils.elemExists(panel)) {
      Utils.attr(tab, 'aria-controls', id)
    }

    if (Utils.hasClass(tab, 'cb-selected')) {
      Utils.attr(tab, 'aria-selected', true)
    } else {
      Utils.attr(tab, 'aria-selected', 'false')
    }

    if (Utils.hasClass(tab, 'cb-disabled')) {
      Utils.attr(tab, 'aria-disabled', true)
    }
  }

  const addEvents = (tab) => {
    // Click event for header
    tab.addEventListener('click', (e) => {
      e.preventDefault()

      toggle(tab)
    })

    tab.addEventListener('keydown', (e) => {
      const k = e.which || e.keyCode;

      // space
      if (k === 32) {
        e.preventDefault()
        
        toggle(tab)
        return;
      }

      //left/right
      if (!/(37|39)/.test(k)) {
        return;
      }

      let index = null
      const items = elem.querySelectorAll('[role=tab]:not(.cb-disabled)');

      Array.prototype.forEach.call(items, function (item, i) {
        if (tab === item) {
          index = i
        }
      });

      if (k === 37) index--; //left
      if (k === 39) index++; //right

      if (index < 0) index = items.length - 1;
      if (index === items.length) index = 0;

      const newActive = items.item(index)
      newActive.focus()
    })
  }

  const toggle = (tab) => {
    if (!tab || Utils.attr(tab, 'aria-selected') == 'true') return;
    resetTabs()
    resetPanels()

    Utils.addClass(tab, 'cb-selected')
    Utils.attr(tab, 'aria-selected', true)

    const panel = document.getElementById(Utils.attr(tab, 'aria-controls'))

    if (!Utils.elemExists(panel)) return
    Utils.addClass(panel, 'cb-selected')

    const event = new CustomEvent('apricot_tabChanged');
    event.data = {
      id: Utils.attr(tab, 'aria-controls')
    }

    elem.dispatchEvent(event);

    if (callBack) {
      callBack(tab)
    }
  }

  const resetTabs = () => {
    Array.prototype.forEach.call(tabs, (tab) => {

      Utils.removeClass(tab, 'cb-selected')
      Utils.attr(tab, 'aria-selected', 'false')
    })
  }

  const resetPanels = () => {
    Array.prototype.forEach.call(panels, (panel, index) => {

      Utils.removeClass(panel, 'cb-selected')
    })
  }

  const destroy = () => {
    if (elem.tabs === 'cb') {
      elem.tabs = null
    }
  }

  if (elem.tabs !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

export default Tabs