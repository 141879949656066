/* ========================================================================
 * Apricot's Masonry Grid
* ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/masonry.scss';

// javaScript
import Utils from './CBUtils'


/**
 * Masonry
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Number} data.columns
 * @param {Object} data.colObj
 * @param {Number} data.colObj.xsColumns
 * @param {Number} data.colObj.smColumns
 * @param {Number} data.colObj.mdColumns
 * @param {Number} data.colObj.lgColumns
 * @param {Number} data.colObj.xlColumns
 * @param {Number} data.colObj.xl2Columns
 * @param {Number} data.colObj.xl3Columns
 * @returns {{destroy: Function}} 
 */
const Masonry = (data = {}) => {
  const defaultData = {
    elem: null,
    columns: 3,
    colObj: null
  }

  data = { ...defaultData, ...data };

  let elem = data.elem;
  if (!Utils.elemExists(elem)) return null;

  const init = () => {
    elem.masonry = 'cb';

    let tmp =  (data.colObj) ? data.colObj : setColumns(data.columns)
    data = { ...data, ...tmp };

    Utils.breakpoints()
    getColumnCount(Utils.viewport().prefix)

    document.addEventListener('apricot_breakpointChange', function (e) {
      const data = e.data

      getColumnCount(data.prefix)
    })
  }

  const masonryGrid = (count) => {
    let data = {}
    let col_heights = []
    let item_heights = []

    for (var i = 0; i < count; i++) {
      col_heights.push(0);
    }
    data.height = 0
    // reset grid
    resetGrid();

    elem.querySelectorAll('.cb-masonry-item').forEach((item, index) => {
      const num = index + 1
      const order = (num) % count || count
      const height = Utils.outerHeight(item)
      const obj = {}

      obj.index = num
      obj.order = order
      obj.height = height

      item.style.order = order
      Utils.attr(item, 'data-cb-oder', order)

      item_heights.push(obj)
      col_heights[order - 1] += height;
    })

    const highest = Math.max.apply(Math, col_heights);

    if (count == 1) {
      elem.style.height = 'auto'
    } else {
      elem.style.height = highest + 'px'
      data.height = highest;
    }

    setTimeout(function () {
      const event = new CustomEvent('apricot_masonryReady');
      elem.dispatchEvent(event);
    }, 100);
  }

  const resetGrid = () => {
    elem.querySelectorAll('.cb-masonry-item').forEach((item) => {
      Utils.removeAttr(item, 'order')
    })
    elem.style.height = 'auto'
  }

  const getColumnCount = (prefix) => {
    let count = 1

    switch (prefix) {
      case 'xs':
        count = data.xsColumns;
        break;
      case 'sm':
        count = data.smColumns;
        break;
      case 'md':
        count = data.mdColumns;
        break;
      case 'lg':
        count = data.lgColumns;
      case 'xl':
        count = data.xlColumns;
        break;
      case '2xl':
        count = data.xl2Columns;
        break;
      case '3xl':
        count = data.xl3Columns;
        break;
    }
    
    masonryGrid(count);
  }

  const setColumns = (col) => {

    let obj = {}
    switch (parseInt(col, 10)) {
      case 2:
        obj.xsColumns = 1
        obj.smColumns = 2
        obj.mdColumns = 2
        obj.lgColumns = 2
        obj.xlColumns = 2
        obj.xl2Columns = 2
        obj.xl3Columns = 2
        break;
      case 3:
        obj.xsColumns = 1
        obj.smColumns = 2
        obj.mdColumns = 3
        obj.lgColumns = 3
        obj.xlColumns = 3
        obj.xl2Columns = 3
        obj.xl3Columns = 3
        break;
      case 4:
        obj.xsColumns = 1
        obj.smColumns = 2
        obj.mdColumns = 3
        obj.lgColumns = 4
        obj.xlColumns = 4
        obj.xl2Columns = 4
        obj.xl3Columns = 4
        break;
    }

    return obj
  }

  const destroy = () => {
    if (elem.masonry === 'cb') {
      elem.masonry = null
      resetGrid()
    }
  }

  if (elem.masonry !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

export default Masonry