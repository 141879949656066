/* ========================================================================
 * Apricot's Date Picker
 * ========================================================================
 *
 * This plugin is written based on litepicker.js
 * https://github.com/wakirin/Lightpick
 * https://wakirin.github.io/Lightpick/
 * ======================================================================== */

// SCSS
import '../scss/includes/button.scss';
import '../scss/includes/popover.scss';
import '../scss/includes/date-picker.scss';

// javaScript
import Popover from './CBPopover'
import InputMask from './CBInputMask'
import Utils from './CBUtils'
import moment from 'moment';

/**
 * Date Picker Popover
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Element} data.popoverNode
 * @param {String|Element} data.startInput
 * @param {String|Element} data.endInput
 * @param {String} data.placement
 * @param {String} data.flipVariations
 * @param {Array} data.offset
 * @param {Number|Object} data.delay 
 * @param {Boolean} data.closeOnClickOutside
 * @param {String} data.dateFormat
 * @param {Boolean} data.inputMask
 * @param {String} data.inputDateFormat
 * @param {String} data.singleLabel
 * @param {String} data.startLabel
 * @param {String} data.endLabel
 * @param {Array} data.offset
 * @param {Number} data.firstDay
 * @param {Array} data.disableDates
 * @param {Boolean} data.disableWeekends
 * @param {Boolean} data.singleDate
 * @param {Moment|String|Number|Date} data.startDate
 * @param {Moment|String|Number|Date} data.endDate
 * @param {Moment|String|Number|Date} data.minDate
 * @param {Moment|String|Number|Date} data.maxDate
 * @param {Number} data.minDays
 * @param {Number} data.maxDays
 * @param {String} data.lang
 * @param {Function} data.onSelect
 * @param {Function} data.onSelectStart
 * @param {Function} data.onSelectEnd
 * @param {Function} data.onUpdate
 * @param {Function} data.onReset
 * @param {Function} data.onShow
 * @param {Function} data.onHide
 * @returns {{hide: Function}} 
 * @returns {{show: Function}} 
 * @returns {{destroy: Function}} 
 */

const DatePicker = (data = {}) => {
	const defaultData = {
		elem: null,
		popoverNode: null,
		startInput: null,
		endInput: null,
		placement: 'bottom-start',
		flipVariations: 'top-start',
		offset: [0, 8],
		delay: {
			show: 200,
			hide: 100
		},
		closeOnClickOutside: true,
		interactiveInput: false,
		inputMask: false,
		dateFormat: 'MM/DD/YY',
		inputDateFormat: 'MM/DD/YYYY',
		singleLabel: 'Date',
		startLabel: 'Start Date',
		endLabel: 'End Date',

		// Date picker
		firstDay: 7, //ISO day of the week (1: Monday, ..., 7: Sunday).
		disableDates: null,
		disableWeekends: false,
		singleDate: false,
		startDate: null,
		endDate: null,
		minDate: null,
		maxDate: null,
		minDays: null,
		maxDays: null,
		lang: 'auto',
		onSelect: null,
		onSelectStart: null,
		onSelectEnd: null,
		onUpdate: null,
		onReset: null,
		onShow: null,
		onHide: null
	}
	data = {
		...defaultData,
		...data
	};

	let elem = data.elem;
	let popoverNode = data.popoverNode ? data.popoverNode : (elem ? document.querySelector(`#${elem.getAttribute('aria-controls')}`) : null);
	if (!Utils.elemExists(popoverNode)) return null;

	let field = null;
	let secondField = null;
	let container = null;
	let updateBtn = null;
	let startInput = null;
	let endInput = null;
	let popperInstance = null;
	let statusTag = null;

	let isShowing = false;

	let format = 'MM/DD/YYYY';
	let dateFormat = format;
	let lang = 'auto'
	let panels = 1;
	let numberOfMonths = 1;
	let activeTarget = null;
	let interactiveInput = data.interactiveInput;

	const init = () => {
		popoverNode.datePicker = 'cb';
		// calendar is placed in container
		container = popoverNode.querySelector('.cb-popover-content .cb-month-container')
		// A11Y, make sure this is in place
		Utils.attr(container, "role", "application")

		updateBtn = popoverNode.querySelector('.cb-filter-update')
		statusTag = popoverNode.querySelector('.sr-only[role="status"]');

		// Start input
		if (typeof data.startInput === 'string' && data.startInput) {
			startInput = document.querySelector(`#${data.startInput}`)
		} else {
			startInput = data.startInput
		}
		if (Utils.elemExists(startInput)) {
			data.startDate = startInput.value;
		}

		// End Input
		if (typeof data.endInput === 'string' && data.endInput) {
			endInput = document.querySelector(`#${data.endInput}`)
		} else {
			endInput = data.endInput
		}
		if (Utils.elemExists(endInput)) {
			data.endInput = endInput.value;
		}

		// settings for interaction inputs
		if (interactiveInput) {
			if (data.InputMask) {
				startInput && InputMask({
					elem: startInput,
					cbMask: "##/##/####"
				});

				endInput && InputMask({
					elem: endInput,
					cbMask: "##/##/####"
				});
			}
			if (Utils.elemExists(endInput) && data.singleDate) {
				data.singleDate = false
			}
			if (!Utils.elemExists(endInput)) {
				data.singleDate = true
			}
		} else {
			field = elem.querySelector('.cb-date-picker-start')
			secondField = elem.querySelector('.cb-date-picker-end')
			if (Utils.elemExists(secondField) && data.singleDate) {
				data.singleDate = false
			}
			if (!Utils.elemExists(secondField)) {
				data.singleDate = true
			}
		}

		dateFormat = data.dateFormat;
		numberOfMonths = data.singleDate ? 1 : 2;

		lang = data.lang;
		if (lang === 'auto') {
			const browserLang = navigator.language || navigator.userLanguage;
			if (browserLang) {
				lang = browserLang;
			} else {
				lang = 'en-US';
			}
		}
		moment.locale(lang.split('-')[0]);

		if (data.singleDate) {
			data.startLabel = data.singleLabel
		} else {
			Utils.breakpoints()
			document.addEventListener('apricot_breakpointChange', (e) => {
				adjustLayout(e.data.prefix)
			})
		}

		// --------- activate popover
		// --------- input
		if (interactiveInput) {
			popperInstance = Popover({
				elem: startInput,
				popoverNode: popoverNode,
				trigger: "manual",
				placement: data.placement,
				flipVariations: data.flipVariations,
				offset: data.offset,
				delay: data.delay,
				closeOnClickOutside: data.closeOnClickOutside,
				filter: true
			})
			startInput.addEventListener('keyup', onKeyUp);

			// adjust CSS style
			startInput.addEventListener('apricot_inputFill', setFocus);
			startInput.addEventListener('apricot_inputFocus', setFocus);
			startInput.addEventListener('change', setFocus);
			startInput.addEventListener('apricot_inputBlur', removeFocus);

			if (endInput) {
				endInput.addEventListener('keyup', onKeyUpFirst);
				endInput.addEventListener('apricot_inputFill', setFocus);
				endInput.addEventListener('apricot_inputFocus', setFocus);
				endInput.addEventListener('change', setFocus);
				endInput.addEventListener('apricot_inputBlur', removeFocus);
			}

			document.addEventListener('keyup', documentEvent, true);
			document.addEventListener('click', documentEvent, true);
		} else {
			popperInstance = Popover({
				elem: elem,
				popoverNode: popoverNode,
				placement: data.placement,
				flipVariations: data.flipVariations,
				offset: data.offset,
				delay: data.delay,
				closeOnClickOutside: data.closeOnClickOutside,
				filter: true
			});

			elem.addEventListener('click', (e) => {
				e.preventDefault();
				if (Utils.hasClass(elem, 'cb-filter-open')) {
					popoverHide(e)
				} else {
					popoverShow(e)
				}
			})
			elem.addEventListener('keydown', (e) => {
				const k = e.which || e.keyCode
				// 32: space
				if (k === 32) {
					elem.click();
				}
			})
			elem.addEventListener('apricot_popover_hide', (e) => {
				popoverHide(e)
			})
		}

		// --------- date picker
		setStartDate(data.startDate, true);
		setEndDate(data.endDate, true);
		// apply style
		if ((data.singleDate && data.startDate) || (!data.singleDate && data.startDate && data.endDate)) {
			Utils.addClass(elem, 'cb-filter-active')
		}

		// If we have a start date, start the calendar in that month
		if (data.startDate) {
			const m = moment(data.startDate).month()
			data.calendar = [moment().set({
				'month': m,
				'date': 1
			})];
		} else {
			data.calendar = [moment().set('date', 1)];
		}

		// A11Y
		setStatusReport()

		adjustLayout(Utils.viewport().prefix)
		addEvents()
	}

	// open popover
	const popoverShow = (e) => {
		data.onShow && data.onShow(e)
		Utils.removeClass(elem, 'cb-filter-active')
		Utils.addClass(elem, 'cb-filter-open')
		isShowing = true
	}
	// close popover
	const popoverHide = (e) => {
		data.onHide && data.onHide(e)
		Utils.removeClass(elem, 'cb-filter-open')
		isShowing = false
		if ((data.singleDate && data.startDate) || (!data.singleDate && data.startDate && data.endDate)) {
			Utils.addClass(elem, 'cb-filter-active')
		}
	}
	// toggle popover
	const popoverToggle = () => {
		popperInstance.toggle();
		if (popoverNode.getAttribute('aria-hidden') == "true") {

			data.onHide && data.onHide(e);
			isShowing = false;
		} else {

			// Update calendar based on input 
			if (startInput) {
				setStartDate(startInput.value);
			}
			if (endInput) {
				setEndDate(endInput.value);
			}
			if (data.startDate) {
				const m = moment(data.startDate).month()
				const y = moment(data.startDate).year()
				data.calendar = [moment().set({
					'year': y,
					'month': m,
					'date': 1
				})];

				renderCalendar(true);
			}


			data.onShow && data.onShow(e);
			isShowing = true;
		}
	}

	// calendar specific events
	const addEvents = () => {
		popoverNode.addEventListener('mousedown', onMouseDown)
		popoverNode.addEventListener('mouseenter', onMouseEnter, true);
		popoverNode.addEventListener('touchend', onMouseDown, true);
		popoverNode.addEventListener('keydown', onKeyDown);
	}
	const documentEvent = (e) => {
		if (!isShowing) {
			return;
		} else if (Utils.whichKey(e) === 'ESC' && !Utils.attr(document.body, 'data-cb-toast')) {
			popperInstance.hide();
		} else {
			if (startInput.contains(e.target) || (endInput && endInput.contains(e.target)) || popoverNode.contains(e.target)) {
				return;
			} else {
				popperInstance.hide();
			}
		}
	}
	const onKeyUp = (e) => {
		// space, down
		if (!e) {
			popoverToggle()
		} else if (e.keyCode === 32 || e.keyCode === 40) {
			popoverToggle()
		}
	}
	const onKeyUpFirst = (e) => {
		// space, down
		if (e.keyCode === 32 || e.keyCode === 40) {
			onKeyUp()
		}
	}
	// calendar
	const onMouseDown = (e) => {
		if (!isShowing) {
			return;
		}

		let target = e.target;
		if (!target) {
			return;
		}
		if (target.classList.contains('cb-day-btn')) {
			e.preventDefault();

			target = Utils.parent(target)
		}

		e.stopPropagation();
		if (target.classList.contains('cb-day') && target.classList.contains('is-available')) {
			const day = moment(parseInt(target.getAttribute('data-time')));

			if (data.singleDate || (!data.startDate && !data.endDate) || (data.startDate && data.endDate)) {
				setStartDate(day);

				if (data.startDate && data.endDate) {
					disabledUpdateBtn(true);
				}
				setEndDate(null);
				target.classList.add('is-start-date');

				if (!data.singleDate || !data.endDate) {
					updateDates();
				}
			} else if (data.startDate && !data.endDate) {
				setEndDate(day);
				if (data.startDate.isAfter(data.endDate)) {
					swapDate();
				}
				target.classList.add('is-end-date');
				updateDates();
			}

			// For Keyboard
			if (activeTarget) {
				const c = container.querySelector(`[data-time="${activeTarget}"]`)
				if (c) {
					Utils.addClass(c, 'is-in-range');
					c.querySelector('a').focus()
				}
			}
		} else if (target.classList.contains('cb-prev-month') || target.classList.contains('cb-gallery-backward')) {
			prevMonth();
		} else if (target.classList.contains('cb-next-month') || target.classList.contains('cb-gallery-forward')) {
			nextMonth();
		} else if (target.classList.contains('cb-filter-reset')) {
			reset();
		} else if (target.classList.contains('cb-filter-update')) {
			if (typeof data.onUpdate === 'function') {
				data.onUpdate(returnDateObj(true, true))
			}
		}
	}
	const setFocus = (e) => {
		//cb-focus-fl
		const parent = Utils.getClosest(startInput, '.cb-input-date-picker');
		Utils.addClass(parent, 'cb-focus-fl')
	}
	const removeFocus = (e) => {
		//cb-focus-fl
		const parent = Utils.getClosest(startInput, '.cb-input-date-picker');
		Utils.removeClass(parent, 'cb-focus-fl')
	}

	const onMouseEnter = (e) => {
		if (!isShowing) {
			return;
		}

		const target = e.target;
		if (!target) {
			return;
		}
		if (data.singleDate || (!data.startDate && !data.endDate)) {
			return;
		}
		if (!target.classList.contains('cb-day') && !target.classList.contains('is-available')) {
			return;
		}
		if (data.startDate && !data.endDate) {
			const hoverDate = moment(parseInt(target.getAttribute('data-time')));

			if (!hoverDate.isValid()) {
				return;
			}

			const startDate = (data.startDate && !data.endDate) ? data.startDate : data.endDate;
			const days = container.querySelectorAll('.cb-day');
			[].forEach.call(days, (day) => {
				const dt = moment(parseInt(day.getAttribute('data-time')));

				if (dt.isValid() && dt.isSameOrAfter(startDate, 'day') && dt.isSameOrBefore(hoverDate, 'day')) {
					day.classList.add('is-in-range');
				} else if (dt.isValid() && dt.isSameOrAfter(hoverDate, 'day') && dt.isSameOrBefore(startDate, 'day')) {
					day.classList.add('is-in-range');
				} else {
					day.classList.remove('is-in-range');
				}
			});
		}
	};
	const onKeyDown = (e) => {
		const k = e.which || e.keyCode;
		if (!isShowing) {
			return;
		}

		let target = e.target;
		if (!target) {
			return;
		}

		//up/down/left/right/space/enter
		if (!/(38|40|37|39|32|13)/.test(k)) {
			return;
		}

		// popover should close
		if (!target.getAttribute('data-cb-popover-close')) {
			e.preventDefault();
		}

		e.stopPropagation();

		if (/(38|40|37|39)/.test(k)) {
			if (target.classList.contains('cb-day-btn')) {
				keyboardInteraction(k, target);
			}
		} else if (/(13|32)/.test(k)) {
			keepFocusOnDay(e)
			onMouseDown(e)
		}
	}
	const keepFocusOnDay = (e) => {
		let target = e.target;
		activeTarget = null
		if (target && target.classList.contains('cb-day-btn')) {
			activeTarget = Utils.attr(Utils.parent(target), 'data-time')
		}
	}
	// node: current active day
	// keyboard, left/right
	const keyboardInteraction = (k, node) => {
		let index = null
		let items = null;

		// extra treatment for up/down
		if (!/(39|37)/.test(k)) {
			if (Utils.hasClass(popoverNode, 'cb-date-picker-double')) {
				let panels = popoverNode.querySelectorAll('.cb-month');
				if (panels[0].contains(node)) {
					items = panels[0].querySelectorAll('.cb-day-btn');
				} else {
					items = panels[1].querySelectorAll('.cb-day-btn');
				}

				keyboardInteractionUPDown(items, node, k);
			} else {
				items = popoverNode.querySelectorAll('.cb-day-btn');
				keyboardInteractionUPDown(items, node, k);
			}

			return;
		}

		items = popoverNode.querySelectorAll('.cb-day-btn:not([aria-disabled]');
		Array.prototype.forEach.call(items, function (item, i) {
			if (node === item) {
				index = i
			}
		});

		if (k === 37) index--; //left
		if (k === 39) index++; //right

		if (index < 0) index = items.length - 1;
		if (index === items.length) index = 0;

		const newActive = items.item(index)
		newActive.setAttribute('tabIndex', '0');
		newActive.focus();
		newActive.addEventListener('blur', resetTabIndex);
	}

	const resetTabIndex = (e) => {
		const node = e.target;
		if (node) {
			if (!node.getAttribute("data-cb-tab")) {
				node.setAttribute('tabIndex', '-1');
			}
			node.removeEventListener('blur', resetTabIndex)
		}
	}
	// Up/Down navigation
	const keyboardInteractionUPDown = (panel, node, k) => {
		let index = 0
		Array.prototype.forEach.call(panel, (item, i) => {
			if (node === item) {
				index = i
			}
		});

		const length = panel.length
		const inRow = Math.floor(index / 7)
		let pass = false
		let newActive = null

		do {
			let tmp = index
			// up
			if (k === 38) {
				//up
				if (inRow === 0) {
					tmp += 28
				} else {
					tmp -= 7;
				}
				if (tmp >= length) tmp = index + 21;
				if (tmp < 0) tmp = index - 21;
			}
			//down
			if (k === 40) {
				if (inRow === 4) {
					tmp -= 28
				} else {
					tmp += 7;
				}
				if (tmp < 0) tmp = index + 21;
				if (tmp >= length) tmp = index - 21;
			}

			index = tmp
			newActive = panel.item(index)
			if (Utils.attr(newActive, 'aria-disabled') == 'true') {
				pass = true
			} else {
				pass = false
			}
		}
		while (pass);

		newActive.setAttribute('tabIndex', '0');
		newActive.focus();
		newActive.addEventListener('blur', resetTabIndex);
	}
	// reset btn, reset calendar
	const reset = () => {
		setStartDate(null, true);
		setEndDate(null, true);

		updateDates();
		disabledUpdateBtn(true);
		setFirstActiveDay();

		if (typeof data.onSelect === 'function') {
			data.onSelect.call(getStartDate(), getEndDate());
		}

		if (typeof data.onReset === 'function') {
			data.onReset.call(getStartDate(), getEndDate());
		}

		// A11Y
		popoverNode.focus();
	}

	// check between 1 || 2 panel layout
	const adjustLayout = (prefix) => {
		if (!data.singleDate) {
			const panel = popoverNode.querySelectorAll('.cb-popover-header .cb-date-picker-month')[1]
			if (prefix === 'xs') {
				Utils.removeClass(popoverNode, 'cb-date-picker-double')
				panel && panel.setAttribute('aria-hidden', 'true')
				panels = 1
			} else {
				Utils.addClass(popoverNode, 'cb-date-picker-double')
				panel && Utils.removeAttr(panel, 'aria-hidden')
				panels = numberOfMonths;
			}
		}
		renderCalendar(true);
	}
	const setStatusReport = () => {
		let msg = data.singleDate ? 'Select Date' : 'Select Start Date and End Date';

		if (data.singleDate && data.startDate) {
			msg = `Selected date ${labelDate(data.startDate)}`
		} else if (data.startDate && data.endDate) {
			msg = `Start date ${labelDate(data.startDate)}. End date ${labelDate(data.endDate)}`
		} else if (data.startDate) {
			msg = `Start date ${labelDate(data.startDate)}. Please select an end date`
		}

		//  make sure we have the tag
		if (Utils.elemExists(statusTag)) {
			statusTag.innerHTML = msg;
		}

		Utils.attr(elem, 'aria-label', msg)
	}
	// add week day, default narrow
	const weekdayName = (day, weekdayStyle) => {
		return new Date(1970, 0, day, 12, 0, 0, 0).toLocaleString(lang, {
			weekday: weekdayStyle || 'narrow'
		});
	}
	// single day
	const renderDay = (date, dummy, extraClass) => {
		if (dummy) return '<div></div>';

		date = moment(date);
		const prevMonth = moment(date).subtract(1, 'month');
		const nextMonth = moment(date).add(1, 'month');

		const day = {
			time: moment(date).valueOf(),
			className: ['cb-day', 'is-available'],
			today: false
		};

		if (extraClass instanceof Array || Object.prototype.toString.call(extraClass) === '[object Array]') {
			extraClass = extraClass.filter((el) => {
				return ['cb-day', 'is-available', 'is-previous-month', 'is-next-month'].indexOf(el) >= 0;
			});
			day.className = day.className.concat(extraClass);
		} else {
			day.className.push(extraClass);
		}

		if (data.disableDates) {
			for (let i = 0; i < data.disableDates.length; i++) {
				if (data.disableDates[i] instanceof Array || Object.prototype.toString.call(data.disableDates[i]) === '[object Array]') {
					const _from = moment(data.disableDates[i][0], format);
					const _to = moment(data.disableDates[i][1], format);

					if (_from.isValid() && _to.isValid() && date.isBetween(_from, _to, 'day', '[]')) {
						day.className.push('is-disabled');
					}
				} else if (moment(data.disableDates[i], format).isValid() && moment(data.disableDates[i], format).isSame(date, 'day')) {
					day.className.push('is-disabled');
				}

				if (day.className.indexOf('is-disabled') >= 0) {
					if (day.className.indexOf('is-start-date') >= 0) {
						setStartDate(null);
						setEndDate(null);
					} else if (day.className.indexOf('is-end-date') >= 0) {
						setEndDate(null);
					}
				}
			}
		}

		if (data.minDays && data.startDate && !data.endDate) {
			if (date.isBetween(moment(data.startDate).subtract(data.minDays - 1, 'day'), moment(data.startDate).add(data.minDays - 1, 'day'), 'day')) {
				day.className.push('is-disabled');

				if (date.isSameOrAfter(data.startDate)) {
					day.className.push('is-forward-selected');
					day.className.push('is-in-range');
				}
			}
		}

		if (data.maxDays && data.startDate && !data.endDate) {
			if (date.isSameOrBefore(moment(data.startDate).subtract(data.maxDays, 'day'), 'day')) {
				day.className.push('is-disabled');
			} else if (date.isSameOrAfter(moment(data.startDate).add(data.maxDays, 'day'), 'day')) {
				day.className.push('is-disabled');
			}
		}

		if (date.isSame(new Date(), 'day')) {
			day.className.push('is-today');
			day.today = true
		}

		if (date.isSame(data.startDate, 'day')) {
			day.className.push('is-start-date');
		}

		if (date.isSame(data.endDate, 'day')) {
			day.className.push('is-end-date');
		}

		if (data.startDate && data.endDate && date.isBetween(data.startDate, data.endDate, 'day', '[]')) {
			day.className.push('is-in-range');
		}

		if (moment().isSame(date, 'month')) {

		} else if (prevMonth.isSame(date, 'month')) {
			day.className.push('is-previous-month');
		} else if (nextMonth.isSame(date, 'month')) {
			day.className.push('is-next-month');
		}

		if (data.minDate && date.isBefore(moment(data.minDate), 'day')) {
			day.className.push('is-disabled');
		}

		if (data.maxDate && date.isAfter(moment(data.maxDate), 'day')) {
			day.className.push('is-disabled');
		}

		if (!data.singleDate && data.startDate && !data.endDate && date.isBefore(data.startDate, 'day')) {
			day.className.push('is-disabled');
		}

		if (data.disableWeekends && (date.isoWeekday() === 6 || date.isoWeekday() === 7)) {
			day.className.push('is-disabled');
		}

		day.className = day.className.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});

		if (day.className.indexOf('is-disabled') >= 0 && day.className.indexOf('is-available') >= 0) {
			day.className.splice(day.className.indexOf('is-available'), 1);
		}

		const div = document.createElement('div');
		div.className = day.className.join(' ');
		div.setAttribute('data-time', day.time);

		const anchor = document.createElement('a');
		anchor.innerHTML = date.get('date');
		anchor.className = "cb-day-btn";
		anchor.setAttribute('role', 'button');
		anchor.setAttribute('aria-label', labelDate(moment(parseInt(day.time))));

		if (date.get('date') == 1 && day.className.indexOf('is-disabled') === -1) {
			// anchor.setAttribute('tabIndex', 0);
			// anchor.setAttribute('data-cb-tab', true);
		} else {
			anchor.setAttribute('tabIndex', '-1');
		}

		day.today && anchor.setAttribute('aria-current', 'date');
		if (day.className.indexOf('is-disabled') >= 0) {
			anchor.setAttribute('aria-disabled', 'true');
		}

		// A11Y
		if (day.className.indexOf('is-start-date') >= 0) {
			if (data.singleDate) {
				anchor.setAttribute('aria-label', `selected date ${labelDate(moment(parseInt(day.time)))}`);
			} else {
				anchor.setAttribute('aria-label', `selected start date ${labelDate(moment(parseInt(day.time)))}`);
			}
			anchor.setAttribute('tabIndex', '0');
			anchor.setAttribute('data-cb-tab', true);
		} else if (day.className.indexOf('is-end-date') >= 0) {
			anchor.setAttribute('aria-label', `selected end date ${labelDate(moment(parseInt(day.time)))}`);
			anchor.setAttribute('tabIndex', '0');
			anchor.setAttribute('data-cb-tab', true);
		}

		div.appendChild(anchor);

		return div.outerHTML;
	}
	const returnDateObj = (s, e) => {
		let start = s && data.startDate ? getStartDate() : null;
		let end = e && data.endDate ? getEndDate() : null;
		const mObj = {
			start: start,
			end: end
		}

		start = start ? start.toDate() : null;
		end = end ? end.toDate() : null;
		const dObj = {
			start: start,
			end: end
		}

		return {
			dateObj: dObj,
			momentObj: mObj
		}
	}
	const labelDate = (d) => {
		let date = new Date(d);
		// request a weekday along with a long date
		let options = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		};

		return date.toLocaleString(lang, options);
	}
	// set start date value and label
	const setStartDate = (date, preventOnSelect) => {
		const dateISO = moment(date, moment.ISO_8601);
		const dateOptFormat = moment(date, format);
		// we don't have a valid date
		if (!dateISO.isValid() && !dateOptFormat.isValid()) {
			data.startDate = null;

			if (Utils.elemExists(field)) {
				field.innerHTML = data.startLabel;
			} else if (interactiveInput && startInput) {
				updateInputValue(1, "");
			}
			// A11Y
			setStatusReport();

			return;
		}

		data.startDate = moment(dateISO.isValid() ? dateISO : dateOptFormat);
		// if (data.singleDate || Utils.elemExists(secondField)) {
		if (Utils.elemExists(field)) {
			field.innerHTML = data.startDate.format(dateFormat);
			// }
		} else if (interactiveInput && startInput) {
			updateInputValue(1, data.startDate.format(data.inputDateFormat));
		}

		// A11Y
		setStatusReport();
		if (data.singleDate) {
			disabledUpdateBtn(false);
		}
		if (!preventOnSelect && typeof data.onSelect === 'function') {
			data.onSelect(returnDateObj(true, true))
		}
		if (!preventOnSelect && !data.singleDate && typeof data.onSelectStart === 'function') {
			data.onSelectStart(returnDateObj(true, false))
		}
	}
	// set end date value and label
	const setEndDate = (date, preventOnSelect) => {
		const dateISO = moment(date, moment.ISO_8601);
		const dateOptFormat = moment(date, format);

		if (!dateISO.isValid() && !dateOptFormat.isValid()) {
			data.endDate = null;

			if (Utils.elemExists(secondField)) {
				secondField.innerHTML = data.endLabel;
			} else if (interactiveInput && endInput) {
				updateInputValue(0, "");
			}

			// A11Y
			setStatusReport();
			return;
		}

		data.endDate = moment(dateISO.isValid() ? dateISO : dateOptFormat);

		if (Utils.elemExists(secondField)) {
			field.innerHTML = data.startDate && data.startDate.format(dateFormat);
			secondField.innerHTML = data.endDate && '<span class="sr-only">to </span>' + data.endDate.format(dateFormat);
			disabledUpdateBtn(false)
		} else if (interactiveInput && endInput) {
			updateInputValue(0, data.endDate.format(data.inputDateFormat));
			disabledUpdateBtn(false)
		}

		// A11Y
		setStatusReport();

		if (!preventOnSelect && typeof data.onSelect === 'function') {
			data.onSelect(returnDateObj(true, true))
		}

		if (!preventOnSelect && !data.singleDate && typeof data.onSelectEnd === 'function') {
			data.onSelectEnd(returnDateObj(false, true))
		}
	}

	// mode: 1 -> startInput
	// mode: 0 -> secondField
	const updateInputValue = (mode, value) => {
		const input = mode ? startInput : endInput;

		input.value = value;
		input.dispatchEvent(new Event('keyup'));
	}

	// Update button, disabled status 
	const disabledUpdateBtn = (status) => {
		if (!updateBtn) return;

		if (status) {
			Utils.attr(updateBtn, 'disabled', true)
		} else {
			Utils.removeAttr(updateBtn, 'disabled')
		}
	}

	// change start and end date
	const swapDate = () => {
		const tmp = moment(data.startDate);
		setDateRange(data.endDate, tmp);
	}
	// set date range in double panel structure
	const setDateRange = (start, end, preventOnSelect) => {
		if (data.singleDate) {
			return;
		}
		setStartDate(start, true);
		setEndDate(end, true);

		if (isShowing) {
			updateDates();
		}

		if (!preventOnSelect && typeof data.onSelect === 'function') {
			data.onSelect.call(getStartDate(), getEndDate());
		}
	}

	// update the days in panels
	const updateDates = () => {
		const days = container.querySelectorAll('.cb-day');
		[].forEach.call(days, (day) => {
			day.outerHTML = renderDay(parseInt(day.getAttribute('data-time')), false, day.className.split(' '));
		});

		checkDisabledDatesInRange();
	}
	// return current start of date range as moment object.
	const getStartDate = () => {

		return moment(data.startDate).isValid() ? data.startDate.clone() : null;
	}
	// return current end of date range as moment object.
	const getEndDate = () => {

		return moment(data.endDate).isValid() ? data.endDate.clone() : null;
	}

	// month name, long format
	const addMonthName = (date, num, id) => {
		const d = moment(date);
		const monthValue = d.toDate().toLocaleString(lang, {
			month: 'long'
		});

		const node = popoverNode.querySelectorAll('.cb-popover-header .cb-date-picker-month')[num];
		const label = node.querySelector('.cb-month-label');
		if (Utils.elemExists(label)) {
			label.innerHTML = monthValue;
		}
	}
	const addYearValue = (date, num, id) => {
		const yearValue = moment(date).toDate().getFullYear();
		const node = popoverNode.querySelectorAll('.cb-popover-header .cb-date-picker-month')[num];
		const label = node.querySelector('.cb-year-label');
		if (Utils.elemExists(label)) {
			label.innerHTML = yearValue;
		}
	}

	// main calendar structure
	const renderCalendar = (mode) => {
		let html = '';
		let monthDate = moment(data.calendar[0]);

		for (let i = 0; i < panels; i++) {
			const day = moment(monthDate);
			const monthId = Utils.uniqueID(5, 'apricot_month');
			const yearId = Utils.uniqueID(5, 'apricot_year');
			html += `<div class="cb-month">`;

			// add Month 
			addMonthName(day, i, monthId)
			addYearValue(day, i, yearId)

			html += '<div class="cb-days-of-the-week">';
			for (let w = data.firstDay + 4; w < 7 + data.firstDay + 4; ++w) {
				html += '<div class="cb-day-of-the-week">' + weekdayName(w) + '</div>';
			}
			html += '</div>';
			html += '<div class="cb-days">';
			let cells = 0

			if (day.isoWeekday() !== data.firstDay) {
				let prevDays = day.isoWeekday() - data.firstDay > 0 ? day.isoWeekday() - data.firstDay : day.isoWeekday();
				let prevMonth = moment(day).subtract(prevDays, 'day');
				let daysInMonth = prevMonth.daysInMonth();

				for (let d = prevMonth.get('date'); d <= daysInMonth; d++) {
					html += renderDay(prevMonth, i > 0, 'is-previous-month');
					cells++;
					if (cells === 7) {
						cells = 0
						html += '</div>';
						html += '<div class="cb-days">';
					}
					prevMonth.add(1, 'day');
				}
			}

			let daysInMonth = day.daysInMonth();
			for (let i = 0; i < daysInMonth; i++) {
				html += renderDay(day);
				cells++;
				if (cells === 7) {
					cells = 0
					html += '</div>';
					html += '<div class="cb-days">';
				}

				day.add(1, 'day');
			}

			let nextMonth = moment(day);
			let nextDays = 7 - nextMonth.isoWeekday() + data.firstDay;
			if (nextDays < 7) {
				for (let j = nextMonth.get('date'); j <= nextDays; j++) {
					html += renderDay(nextMonth, i < panels - 1, 'is-next-month');

					nextMonth.add(1, 'day');
				}
			}
			html += '</div>'; // cb-days
			html += '</div>'; // cb-month

			monthDate.add(1, 'month');
		}

		data.calendar[1] = moment(monthDate);
		container.innerHTML = html;

		mode && setFirstActiveDay();
	}
	// set range of disabled dates
	const checkDisabledDatesInRange = () => {
		if (!data.startDate || data.endDate || !data.disableDates) {

			setFirstActiveDay();
			return;
		}

		const days = container.querySelectorAll('.cb-day');
		const disabledArray = data.disableDates.map((entry) => {
			return entry instanceof Array || Object.prototype.toString.call(entry) === '[object Array]' ? entry[0] : entry;
		});

		const closestPrev = moment(disabledArray.filter((d) => {
			return moment(d).isBefore(data.startDate);
		}).sort((a, b) => {
			return moment(b).isAfter(moment(a));
		})[0]);
		const closestNext = moment(disabledArray.filter((d) => {
			return moment(d).isAfter(data.startDate);
		}).sort((a, b) => {
			return moment(a).isAfter(moment(b));
		})[0]);

		[].forEach.call(days, (dayCell) => {
			const day = moment(parseInt(dayCell.getAttribute('data-time')));
			if (
				(closestPrev && day.isBefore(closestPrev) && data.startDate.isAfter(closestPrev)) ||
				(closestNext && day.isAfter(closestNext) && closestNext.isAfter(data.startDate))
			) {
				dayCell.classList.remove('is-available');
				dayCell.classList.add('is-disabled');
			}
		});

		setFirstActiveDay();
	}
	const setFirstActiveDay = () => {
		const months = container.querySelectorAll('.cb-month');

		[].forEach.call(months, (month, index) => {
			let hasActive = month.querySelectorAll('a[data-cb-tab="true"]').length > 0;
			const days = month.querySelectorAll('.cb-day');
			[].forEach.call(days, (dayCell) => {
				if (!hasActive) {
					// only check active days in a month
					if (!Utils.hasClass(dayCell, "is-previous-month") && !Utils.hasClass(dayCell, "is-disabled")) {
						dayCell.querySelector('a').setAttribute('data-cb-tab', true);
						dayCell.querySelector('a').setAttribute('tabIndex', '0');
						hasActive = true;
					}
				}
			});
		})
	}
	// go to prev month
	const prevMonth = () => {
		data.calendar[0] = moment(data.calendar[0]).subtract(numberOfMonths, 'month');

		renderCalendar();
		checkDisabledDatesInRange();
	}
	// go to next month
	const nextMonth = () => {
		data.calendar[0] = moment(data.calendar[1]);

		renderCalendar();
		checkDisabledDatesInRange();
	}

	// --------- public
	const hide = () => {
		popperInstance.hide();
		popoverHide()
	}
	const show = () => {
		popperInstance.show();
		popoverShow()
	}
	const destroy = () => {
		if (elem.datePicker === 'cb') {
			elem.datePicker = null

			popperInstance && popperInstance.destroy();
			// remove events
			popoverNode.removeEventListener('mousedown', onMouseDown)
			popoverNode.removeEventListener('mouseenter', onMouseEnter, true);
			popoverNode.removeEventListener('touchend', onMouseDown, true);
			popoverNode.removeEventListener('keydown', onKeyDown);

			if (interactiveInput) {
				startInput.removeEventListener('keyup', onKeyUp);
				endInput.removeEventListener('keyup', onKeyUpFirst);
				document.removeEventListener('keyup', documentEvent, true);
				document.removeEventListener('click', documentEvent, true);
			}

			container.innerHTML = "";
			field.innerHTML = data.startLabel;
			if (Utils.elemExists(secondField)) {
				secondField.innerHTML = data.endLabel;
			}
		}
	}

	if (popoverNode.datePicker !== 'cb') {
		init();
	}

	return {
		hide: hide,
		show: show,
		destroy: destroy
	}
}

export default DatePicker;