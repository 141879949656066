/* ========================================================================
 * Apricot's Text with Media
* ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/text-with-media.scss';
import '../scss/includes/glyph.scss';
import '../scss/includes/grid.scss';

// javaScript
export default {}