/* ========================================================================
 * Apricot's Back To Top
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/button.scss';
import '../scss/includes/back-to-top.scss';

// javaScript
import Utils from './CBUtils'
import Animation from './CBAnimation'

/**
 * Back To Top 
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Element} data.topOfPageAnchor
 * @param {Number} data.offsetTop
 * @param {Boolean} data.animation
 * @param {Number} data.animationTime
 * @returns {{destroy: Function}} 
 */
const BackToTop = (data = {}) => {
  const defaultData = {
    elem: null,
    topOfPageAnchor: null,
    offsetTop: 72,
    animation: true,
    animationTime: 500
  }

  data = {
    ...defaultData,
    ...data
  };

  let elem = data.elem;
  let topOfPageAnchor = data.topOfPageAnchor;
  let offsetTop = data.offsetTop;
  const winElem = window

  if (!Utils.elemExists(elem)) return null;

  const init = () => {
    elem.backToTop = 'cb';

    winElem.addEventListener('scroll', scrollFunction)
    elem.addEventListener('click', scrollUp)
  }

  const scrollFunction = () => {
    if (document.body.scrollTop > offsetTop || document.documentElement.scrollTop > offsetTop) {
      Utils.removeClass(elem, 'cb-back-to-top-hide')
      Utils.addClass(elem, 'cb-back-to-top-show')
      Utils.attr(elem, 'aria-hidden', 'false')
      Utils.attr(elem, 'tabIndex', '0')
    } else {
      Utils.removeClass(elem, 'cb-back-to-top-show')
      Utils.addClass(elem, 'cb-back-to-top-hide')
      Utils.attr(elem, 'aria-hidden', 'true')
      Utils.attr(elem, 'tabIndex', '-1')
    }
  }

  const scrollUp = e => {
    e.preventDefault()

    let ms = (data.animation) ? data.animationTime : 0
    let offsetTop = Utils.offset(elem).top

    ms = (offsetTop * ms) / 800

    // this is not be supported in all browsers
    if (Utils.reduceMotionChanged()) ms = 0

    Animation.pageScrollAnimate(
      0,
      ms,
      'linear',
      setFocus
    )

  }

  const setFocus = () => {
    if (Utils.elemExists(topOfPageAnchor)) {
      topOfPageAnchor.focus()
    } else {
      console.warn('Apricot Back to Top: Seems like you have missed topOfPageAnchor')
      return false
    }
  }

  const destroy = () => {
    if (elem.backToTop === 'cb') {
      elem.backToTop = null

      winElem.removeEventListener('scroll', scrollFunction)
      elem.removeEventListener('click', scrollUp)
    }
  }

  if (elem.backToTop !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

export default BackToTop