/* ========================================================================
 * Apricot's Spinner
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/spinner.scss';

// javaScript
import Utils from './CBUtils'

/**
 * Animated Spinner
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Number} data.min
 * @param {Number} data.max
 * @param {Number} data.end
 * @param {Number} data.duration
 * @param {String} data.suffix
 * @param {Function} data.callBack
 * @returns {{destroy: Function}} 
 */

const Animated = (data = {}) => {
    const defaultData = {
        elem: null,
        min: 0,
        max: 100,
        end: 100,
        duration: 20000,
        suffix: '%',
        callBack: null
    }

    data = {
        ...defaultData,
        ...data
    };

    const min = data.min;
    const max = data.max;
    const elem = data.elem;
    const end = data.end;
    let duration = data.duration;
    let startTimestamp = null;
    let indicator = null

    const init = () => {
        elem.counter = 'cb';

        indicator = elem.querySelector('.cb-spinner-indicator');

        // A11Y
        Utils.attr(elem, "aria-valuemin", `${min}`);
        Utils.attr(elem, "aria-valuemax", `${max}`);
        Utils.attr(elem, "aria-valuenow", `${min}`);
        Utils.attr(elem, "aria-valuetext", `${end}%`);

        if (Utils.reduceMotionChanged()) {
            duration = 0
        }

        window.requestAnimationFrame(step);
    }

    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        let newValue = Math.floor(progress * (end - min) + min);
        if (Utils.reduceMotionChanged()) {
            newValue = end
        }
        indicator.innerHTML = `${newValue}${data.suffix}`;
        Utils.attr(elem, "aria-valuenow", newValue);

        if (progress < 1) {
            window.requestAnimationFrame(step);
        } else {
            if (data.callBack) {
                data.callBack(elem);
            }
        }
    };

    const destroy = () => {
        if (elem.counter === 'cb') {
            elem.counter = null
            Utils.removeAttr(elem, "aria-valuemin");
            Utils.removeAttr(elem, "aria-valuemax");
            Utils.removeAttr(elem, "aria-valuenow");
        }
    }

    if (elem.tabs !== 'cb') {
        init();
    }

    return {
        destroy: destroy
    }
}


/**
 * Progress Indicator 
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Number} data.end
 * @param {Number} data.duration
 * @param {String} data.suffix
 * @param {Function} data.callBack
 * @returns {{destroy: Function}} 
 */

const Progress = (data = {}) => {
    const defaultData = {
        elem: null,
        end: 100,
        duration: 800,
        suffix: '%',
        callBack: null
    }

    data = {
        ...defaultData,
        ...data
    };

    const elem = data.elem;
    const end = data.end
    let duration = data.duration

    if (!Utils.elemExists(elem)) return null;
    const init = () => {
        elem.progress = 'cb';

        const indicator = elem.querySelector('.cb-spinner-indicator');
        const left = elem.querySelector('.cb-spinner-left');
        const right = elem.querySelector('.cb-spinner-right');
        const rotate = elem.querySelector('.cb-spinner-rotate');

        indicator.innerHTML = `${end}${data.suffix}`;

        Utils.attr(elem, 'aria-label', `${end}${data.suffix} complete`)

        if (Utils.reduceMotionChanged()) {
            duration = 0
        }

        //  add a minor delay 
        setTimeout(() => {
            rotate.style.transition = 'transform ' + duration + 'ms linear'
            rotate.style.transform = 'rotate(' + end * 3.6 + 'deg)'
        }, 1);

        if (end > 50) {
            right.style.animation = `toggle ${duration / end * 50}ms step-end`;
            right.style.opacity = 1;

            left.style.animation = `toggle ${duration / end * 50}ms step-start`;;
            left.style.opacity = 0;
        }
        if (data.callBack) {
            data.callBack(elem);
        }
    }

    // Remove 
    const destroy = () => {
        if (elem.progress === 'cb') {
            elem.progress = null
        }
    }

    if (elem.progress !== 'cb') {
        init();
    }

    return {
        destroy: destroy
    }
}

const CBSpinner = {
    Animated,
    Progress
}

export default CBSpinner;